<template>
  <mex-dialog :showDialog="showNoteEditDialog" dialogColor="note" dialogTitle="Edit Note" width="50%">
    <template v-slot:dialog-content>
      <v-row justify="center">
        <v-col class="align-stretch" cols="12">
          <v-textarea v-model="noteContent" dense label="Note" outlined rows="6"></v-textarea>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col align-self="center" cols="auto">
          <v-checkbox v-model="sendNotification" label="Send notification" />
        </v-col>
        <v-col align-self="center" cols="auto">
          <v-select
            v-model="selectedNotificationType"
            :items="notificationTypes"
            :readonly="!sendNotification"
            dense
            filled
            hide-details
            label="Notification Type"
            outlined
          >
            <template v-slot:selection="data">
              <v-icon v-if="data.item.text === 'Success'" class="mr-1" color="success">mdi-check</v-icon>
              <v-icon v-if="data.item.text === 'Warning'" class="mr-1" color="warning">mdi-alert</v-icon>
              <v-icon v-if="data.item.text === 'Error'" class="mr-1" color="error">mdi-alert-circle-outline</v-icon>
              <v-icon v-if="data.item.text === 'Info'" class="mr-1" color="info">mdi-information</v-icon>
              {{ data.item.text }}
            </template>
          </v-select>
        </v-col>
      </v-row>
    </template>
    <template slot="dialog-actions">
      <v-row justify="center">
        <v-col cols="auto">
          <mex-btn v-if="editNotes" content="Save Note" icon="mdi-content-save-edit" @click="saveNote" />
        </v-col>
        <v-col cols="auto">
          <mex-btn content="Minimize" icon="mdi-window-minimize" @click="$emit('minimize')" />
        </v-col>
        <v-col cols="auto">
          <mex-btn content="Discard" icon="mdi-delete" @click="discradNoteDialog" />
        </v-col>
      </v-row>
    </template>
  </mex-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationTypesService from '../../services/notificationTypes.service';
import NotesService from '../../services/notes.service';

export default {
  name: 'NoteDialog',
  props: {
    showNoteEditDialog: {
      type: Boolean,
      default: true,
    },
    noteID: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      // notification
      sendNotification: true,
      selectedNotificationType: 4,
      noteContent: '',
    };
  },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    editNotes() {
      return this.getUserPermissions.includes('edit_Customer Administration_Note');
    },
  },
  watch: {
    noteID: {
      handler() {
        this.fetchNote();
      },
    },
  },
  methods: {
    fetchNote() {
      if (this.noteID) {
        NotesService.getNoteByID(this.noteID)
          .then((noteResponse) => {
            this.noteContent = noteResponse.data.message;
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchNotificationTypes() {
      NotificationTypesService.getNotificationTypes().then((notificationTypesResponse) => {
        notificationTypesResponse.data.forEach((type) => {
          this.notificationTypes.push({
            value: type.NotificationTypeID,
            text: type.name,
          });
        });
      });
    },
    discradNoteDialog() {
      this.fetchNote();
      this.noteID = null;
      this.$emit('reject');
    },
    saveNote() {
      NotesService.updateNote(this.noteID, this.noteContent)
        .then(() => {
          this.$emit('accept');
          this.$toast.success('Note successfully updated');
          this.noteID = null;
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    },
  },
};
</script>

<style></style>
