<template>
  <mex-sheet color="transparent" elevation="0">
    <v-row>
      <v-col cols="auto">
        <v-text-field v-model="address.street" :readonly="!editMode" color="primaryAccent" dense filled hide-details label="Street" outlined />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-text-field
          v-model="address.postalCode"
          :readonly="!editMode"
          color="primaryAccent"
          dense
          filled
          hide-details
          label="Postalcode"
          outlined
        />
      </v-col>
      <v-col cols="auto">
        <v-text-field v-model="address.city" :readonly="!editMode" color="primaryAccent" dense filled hide-details label="City" outlined />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <v-text-field v-model="address.province" :readonly="!editMode" color="primaryAccent" dense filled hide-details label="Province" outlined />
      </v-col>
      <v-col cols="auto">
        <v-text-field v-model="address.Country.name" :readonly="!editMode" color="primaryAccent" dense filled hide-details label="Country" outlined />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-divider horizontal />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="address.mobileNumber"
          :readonly="!editMode"
          color="primaryAccent"
          dense
          filled
          hide-details
          label="Mobile"
          outlined
          prepend-inner-icon="mdi-cellphone"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="address.phoneNumber"
          :readonly="!editMode"
          color="primaryAccent"
          dense
          filled
          hide-details
          label="Phone"
          outlined
          prepend-inner-icon="mdi-phone"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-divider horizontal />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="address.email"
          :readonly="!editMode"
          color="primaryAccent"
          dense
          filled
          hide-details
          label="E-Mail"
          outlined
          prepend-inner-icon="mdi-email"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="address.faxNumber"
          :readonly="!editMode"
          color="primaryAccent"
          dense
          filled
          hide-details
          label="Fax"
          outlined
          prepend-inner-icon="mdi-fax"
        />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-divider horizontal />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea :value="address.memo" dense label="Memo" outlined prepend-icon="mdi-note"></v-textarea>
      </v-col>
    </v-row>
    <!-- <v-row justify="center">
      <v-col cols="auto">
        <mex-btn v-if="!editMode" content="Edit" @click="editMode = true" />
        <mex-btn v-else content="Save Changes" @click="saveChanges" />
      </v-col>
      <v-col cols="auto">
        <mex-btn v-if="editMode" content="Cancel" @click="getClinicViewData" />
      </v-col>
    </v-row> -->
  </mex-sheet>
</template>

<script>
import AddressesServices from '../../services/addresses.service';

/**
 * Takes in a address object from the database and represents it
 *
 * TODO: This will also be used to take in new addresses added through the systemUsers
 */
export default {
  name: 'AddressView',
  props: {
    /**
     * database address object
     */
    address: {
      type: Object,
      default: () => {
        return {};
      },
      description: '',
    },
    organizationID: {
      type: String,
      default: null,
    },
    clinicID: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      editMode: false,
    };
  },
  methods: {
    saveChanges() {
      AddressesServices.updateAddress(this.address.AddressID, this.address)
        .then(() => {
          this.$toast.success('Address successfully updated');
        })
        .catch((err) => {
          this.$toast.error(err);
        });
    },
  },
};
</script>

<style></style>
