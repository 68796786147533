import httpClient from '../httpClient/httpClient';

const AddressesService = {
  updateAddress(addressID, addressData) {
    return httpClient.post('api/update-address', {
      addressID,
      addressData,
    });
  },
};

export default AddressesService;
