<template>
  <expansion-sheet color="foreground" max-height="100%" rounded :show-arrow="false">
    <template v-slot:header>
      <v-row>
        <v-col>
          <mex-p class="mb-0" content="Notes" fontSize="h5" fontWeight="bold-italic" />
        </v-col>
        <v-col v-if="showCloseButton" class="text-end">
          <mex-btn tooltip="Minimize Note-Window" icon-only @click="closeButtonClicked" icon="mdi-close"></mex-btn>
        </v-col>
      </v-row>
    </template>
    <template v-slot:content>
      <v-row>
        <v-col class="pt-0" cols="12">
          <mex-sheet class="noteSheetInner" color="foreground" elevation="0" rounded>
            <v-row v-for="(note, idx) in notes" :key="idx">
              <v-col cols="12">
                <note-chip
                  :key="idx"
                  :noteContent="note.message"
                  :noteDate="$dateFormatter.convertJsonDate(note.updatedAt).date"
                  :noteID="note.NoteID"
                  :noteUsername=usernameCheck(note.SystemUser)
                  @deleteNote="deleteNoteCheck"
                  @editNote="editNote"
                />
              </v-col>
            </v-row>
            <v-row v-if="notes.length === 0">
              <v-col cols="12">
                <mex-sheet color="transparent" elevation="0">
                  <v-row justify="center">
                    <v-col cols="auto">
                      <mex-p class="mb-0" content="No notes attached" fontSize="overline" />
                    </v-col>
                  </v-row>
                </mex-sheet>
              </v-col>
            </v-row>
          </mex-sheet>
        </v-col>
      </v-row>
      <note-edit-dialog
        :noteID="noteEditID"
        :showNoteEditDialog="showNoteEditDialog"
        @accept="reloadNotes"
        @minimize="showNoteEditDialog = false"
        @reject="showNoteEditDialog = false"
      />
      <mex-dialog
        :showDialog="showDeleteNoteCheck"
        dialogAcceptButtonTitle="Delete"
        dialogRejectButtonTitle="Cancel"
        dialogText="Do they really want to delete this note? If the note has been deleted, it cannot be restored."
        dialogTitle="Delete note"
        width="50%"
        @accept="deleteNote"
        @reject="showDeleteNoteCheck = false"
      >
      </mex-dialog>
    </template>
  </expansion-sheet>
</template>

<script>
import NotesService from '../../services/notes.service';
import NoteChip from './NoteChip.vue';
import NoteEditDialog from './NoteEditDialog.vue';
import ExpansionSheet from './ExpansionSheet.vue';
import MexBtn from "../MedITEX_Vue_Components/MexComponents/MexButton.vue";

export default {
  name: 'NoteSheet',
  components: {
    MexBtn,
    NoteChip,
    NoteEditDialog,
    ExpansionSheet,
  },
  props: {
    notes: {
      type: Array,
      default: () => {
        return [];
      },
      description: '',
    },
    showCloseButton: {
      type: Boolean,
      default: () => {
        return false;
      },
      description: ''
    }
  },
  data() {
    return {
      noteEditID: null,
      showNoteEditDialog: false,
      showDeleteNoteCheck: false,
    };
  },
  methods: {
    closeButtonClicked() {
      this.$emit('clickedCloseButton');
    },
    editNote(value) {
      this.showNoteEditDialog = true;
      this.noteEditID = value;
    },
    deleteNote() {
      NotesService.deleteNote(this.noteEditID)
        .then(() => {
          this.$toast.success('Note successfully deleted!');
          this.reloadNotes();
        })
        .catch((err) => {
          this.$toast.error(err);
          this.reloadNotes();
        });
    },
    deleteNoteCheck(value) {
      this.noteEditID = value;
      this.showDeleteNoteCheck = true;
    },
    reloadNotes() {
      this.showNoteEditDialog = false;
      this.showDeleteNoteCheck = false;
      this.$emit('reloadNotes');
    },
    usernameCheck(value){
      if(value){
        return '\t-\t' + value.username
      }
      else {
        return ''
      }
    }
  },
};
</script>

<style scoped>
/* Track */
::-webkit-scrollbar-track {
  background: var(--v-primary-base);
  border-radius: 10px;
  box-shadow: inset 0 0 5px var(--v-primary-darken2);
}

.noteSheetInner {
  box-shadow: inset 0 0 5px var(--v-primary-darken2) !important;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
