<template>
  <mex-sheet color="note" elevation="0">
    <v-row class="ml-1 mr-1">
      <v-col cols="10">
        <v-row justify="center">
          <v-col class="pa-0" cols="12">
            <mex-p :content="noteDate + noteUsername" class="ma-0" fontSize="subtitle" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12">
            <mex-p :content="noteContent" class="ma-0" fontSize="caption" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-row>
          <v-col class="pa-0" cols="12">
            <mex-btn icon="mdi-pencil" iconOnly x-small @click="editNote" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12">
            <mex-btn v-if="editNotes" icon="mdi-close" iconOnly x-small @click="deleteNote" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </mex-sheet>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NoteChip',
  props: {
    noteDate: {
      type: String,
      default: '',
      description: '',
    },
    noteUsername: {
      type: String,
      default: '',
      description: '',
    },
    noteContent: {
      type: String,
      default: '',
      description: '',
    },
    noteID: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    editNotes() {
      return this.getUserPermissions.includes('edit_Customer Administration_Note');
    },
  },
  methods: {
    editNote() {
      this.$emit('editNote', this.noteID);
    },
    deleteNote() {
      this.$emit('deleteNote', this.noteID);
    },
  },
};
</script>

<style></style>
