import httpClient from '../httpClient/httpClient';

const NotesService = {
  setNote(noteContent, notificationType, organization, clinic, location) {
    return httpClient.post('api/set-note', {
      noteContent,
      notificationType,
      organization,
      clinic,
      location,
    });
  },
  getNotes(organization, clinic, location) {
    return httpClient.get('api/get-notes', {
      params: { organization, clinic, location },
    });
  },
  getNoteByID(noteID) {
    return httpClient.get('api/get-note-by-id', {
      params: { noteID },
    });
  },
  updateNote(noteID, noteContent) {
    return httpClient.post('api/update-note', {
      noteID,
      noteContent,
    });
  },
  deleteNote(noteID) {
    return httpClient.post('api/delete-note', {
      noteID,
    });
  },
};

export default NotesService;
